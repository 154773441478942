import { MainConfig } from '../models';

export const mainConfig: MainConfig = {
  mainNav: [
    {
      title: 'Servicios',
      href: '/',
      key: 'how-it-works',
    },
    {
      title: 'Preguntas frecuentes',
      href: '/',
      key: 'faqs',
    },
    {
      title: 'Calculadora',
      href: '/',
      key: 'hero',
    },
    {
      title: 'Métodos de pago',
      href: '/',
      key: 'payment-methods',
    },
  ],
};
